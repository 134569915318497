
import HorizontalScroll from '../utils/HorizontalScroll.vue'
import Vue, { PropOptions } from 'vue'
import { Route, Location } from 'vue-router'
import VueI18n from 'vue-i18n'

export type TabBarOptionValue = null | boolean | string

export interface TabBarOption {
  title?: string | VueI18n.TranslateResult
  i18n?: boolean
  value: TabBarOptionValue
  hidden?: boolean
  route?: string | Route | Location
  [key: string]: any
}

export default Vue.extend({
  name: 'TabBar',
  components: { HorizontalScroll },
  props: {
    /**
     * Tabs array with { title: string, value: string, route: object }
     */
    tabs: {
      type: Array,
      default: () => [],
    } as PropOptions<TabBarOption[]>,
    /**
     * Value of active tab
     */
    currentTab: {
      type: [String, Number],
      required: false,
      default: null,
    } as PropOptions<TabBarOptionValue>,
    /**
     * Use nuxt-link instead of a link
     */
    useNuxtLink: {
      type: Boolean,
      default: false,
    },
    useButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineStyle: {
        transform: 'translateX(0)',
        width: '0',
      } as Partial<CSSStyleDeclaration>,
    }
  },
  computed: {
    linkType(): string {
      return this.useNuxtLink ? 'nuxt-link' : this.useButton ? 'button' : 'a'
    },
    visibleTabs(): TabBarOption[] {
      return this.tabs.filter((tab) => {
        return tab.hidden === undefined || tab.hidden === false
      })
    },
  },
  watch: {
    /**
     * Watch tab change
     * @param newTab
     */
    currentTab(newTab: TabBarOptionValue) {
      this.setLineStyle(newTab)
    },
    /**
     * Update of tabs array
     */
    visibleTabs() {
      this.$nextTick(() => this.setLineStyle())
    },
  },
  mounted() {
    this.setLineStyle()
  },
  methods: {
    /**
     * Get link bindings
     * @param tab
     * @returns {{to: *}|{href: string}}
     */
    getLinkBindings(tab: TabBarOption) {
      if (this.useNuxtLink) {
        return {
          to: tab.route,
        }
      } else {
        return {
          href: '#',
        }
      }
    },
    /**
     *
     * @param tab {{title: string}, {value: string}}
     * @param event {Event}
     */
    handleClick(tab: TabBarOption, event: MouseEvent) {
      if (this.useNuxtLink) {
        // automated routing by nuxt
      } else {
        this.$emit('onClick', tab.value)
        event.preventDefault()
      }
      this.$emit('update:currentTab', tab.value)
    },
    /**
     * Update line position and size
     * @returns {{transform: string, width: string}}
     */
    async setLineStyle(currentTab?: TabBarOptionValue) {
      if (!currentTab) {
        currentTab = this.currentTab
      }

      const tabRefKey = `tab_${currentTab}`
      if (tabRefKey in this.$refs) {
        const tabs = this.$refs[tabRefKey] as HTMLElement[]
        const tab = tabs[0]
        let width = tab.clientWidth
        let offsetLeft = tab.offsetLeft
        this.lineStyle = {
          transform: `translateX(${offsetLeft}px)`,
          width: width + 'px',
        }
      }
    },
  },
})
