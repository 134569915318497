
import Vue, { PropType } from 'vue'
import Popper from '../popovers/Popper.vue'
import AnnyDatePicker from '../input/AnnyDatePicker.vue'
import Date from '../utils/Date.vue'
import VueClickaway from 'vue-clickaway'
import { set } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'

/**
 * Return normalized date
 * @param date {dayjs.Dayjs}
 * @return {string}
 */
function getNormalizedDate(date: Dayjs) {
  return date.add(1, 'hour').set('minute', 0).set('second', 0).format()
}

type Shortcut = {
  text: string
  onClick: () => string
}

export default Vue.extend({
  name: 'ResourceSearch',
  components: {
    Date,
    Popper,
    AnnyDatePicker,
  },
  directives: {
    clickaway: VueClickaway.directive,
  },
  props: {
    /**
     * Set selectable categories
     */
    categories: {
      type: Array as PropType<ResourceCategory[]>,
      default: () => [],
    },
    /**
     * Object to hold all filters
     * should be used as synced prop
     */
    filter: {
      type: Object,
      required: true,
    },
    useCommonHours: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uid: 'search_',
      searchActive: false,
      startDateActive: false,
      endDateActive: false,
      showTimePanel: false,
      startDateShortcuts: [
        // set today
        {
          text: this.$i18n.t(
            'common.components.calendars.datepicker.shortcuts.today'
          ) as string,
          onClick: () => {
            return getNormalizedDate(dayjs())
          },
        },
        // set tomorrow
        {
          text: this.$i18n.t(
            'common.components.calendars.datepicker.shortcuts.tomorrow'
          ) as string,
          onClick: () => {
            return getNormalizedDate(dayjs().add(1, 'day'))
          },
        },
      ] as Shortcut[],
      endDateShortcuts: [
        // add one hour
        {
          text:
            '+ ' +
            this.$i18n.tc(
              'common.components.calendars.datepicker.shortcuts.duration.hour',
              1
            ),
          onClick: () => {
            if (this.filter.startDate) {
              return dayjs(this.filter.startDate).add(1, 'hour').format()
            } else {
              return null
            }
          },
        },
        // add two hours
        {
          text:
            '+ ' +
            this.$i18n.tc(
              'common.components.calendars.datepicker.shortcuts.duration.hour',
              2
            ),
          onClick: () => {
            if (this.filter.startDate) {
              return dayjs(this.filter.startDate).add(2, 'hour').format()
            } else {
              return null
            }
          },
        },
      ] as Shortcut[],
    }
  },
  computed: {
    /**
     * Map categories to tabs
     * @return {{title: string, value: string}[]}
     */
    tabs(): { title: string; value: string }[] {
      let categories = this.categories.map((category: ResourceCategory) => ({
        title: category.pluralName,
        value: category.id,
      }))
      return [
        {
          title: String(this.$i18n.t('common.terms.all')),
          value: '',
        },
        ...categories,
      ]
    },
    /**
     * Get Selected category id
     * @return {string}
     */
    selectedTab(): string {
      let categories = this.filter.categories
      if (Array.isArray(categories) && categories.length > 0) {
        return categories[0]
      } else if (typeof categories === 'string') {
        return categories
      } else {
        return ''
      }
    },
    /**
     * Get active date
     * @return {null|*}
     */
    activeDate(): null | string {
      if (this.startDateActive) {
        return this.filter.startDate
      } else if (this.endDateActive) {
        return this.filter.endDate
      } else {
        return null
      }
    },
    /**
     * Get shortcuts for active date
     */
    activeDateShortcuts(): Shortcut[] {
      let defaultShortcuts = [
        {
          text: this.$i18n.t('common.actions.delete') as string,
          onClick: () => {
            return ''
          },
        } as Shortcut,
      ]
      if (this.startDateActive) {
        return [...defaultShortcuts, ...this.startDateShortcuts]
      } else if (this.endDateActive) {
        return [...defaultShortcuts, ...this.endDateShortcuts]
      } else {
        return []
      }
    },
  },
  mounted() {
    this.uid += this._uid
  },
  methods: {
    /**
     * Update Category filter
     */
    selectCategory(categoryId: string) {
      this.updateFilter('categories', categoryId)
    },
    /**
     * Focus start date
     */
    selectStartDate(): void {
      this.showTimePanel = false
      this.startDateActive = true
      this.endDateActive = false
      if (this.$refs.datePickerPopper) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.datePickerPopper.open(this.$refs.startDate)
      }
    },
    /**
     * Focus end date if start date is set
     */
    selectEndDate(): void {
      // check if start date was selected
      if (!this.filter.startDate) {
        this.selectStartDate()
      } else {
        this.showTimePanel = false
        this.endDateActive = true
        this.startDateActive = false
        if (this.$refs.datePickerPopper) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$refs.datePickerPopper.open(this.$refs.endDate)
        }
      }
    },
    /**
     * Set active date
     */
    updateActiveDate(date: string | null) {
      if (this.startDateActive) {
        // unset start date and end date
        if (date === null || date === '') {
          this.updateMultipleFilters({
            startDate: null,
            endDate: null,
          })
        }
        // keep duration when selecting new start date
        else if (this.filter.endDate) {
          let diff = Math.abs(
            dayjs(this.filter.endDate).diff(this.filter.startDate, 'minute')
          )
          let newEndDate = dayjs(date).add(diff, 'minute')
          this.updateMultipleFilters({
            startDate: date,
            endDate: newEndDate.format(),
          })
        } else {
          let today = dayjs()
          let dayjsDate = dayjs(date)
          // set default start time
          if (dayjsDate.hour() === 0 && dayjsDate.minute() === 0) {
            let normalizedToday = dayjs(getNormalizedDate(today))
            dayjsDate = dayjsDate
              .set('minute', normalizedToday.minute())
              .set('hour', normalizedToday.hour())
          }
          this.updateFilter('startDate', dayjsDate.format())
        }
      } else if (this.endDateActive) {
        this.updateFilter('endDate', date)
      }

      // switch view
      if (!this.showTimePanel) {
        this.showTimePanel = true
      }
    },

    /**
     * Update filter
     * @param key
     * @param value
     */
    updateFilter(key: string, value: any) {
      let filter = { ...this.filter }
      set(filter, key, value)
      filter.availabilityExactMatch = !!filter.startDate && !!filter.endDate
      this.$emit('update:filter', filter)
    },
    updateMultipleFilters(newFilters: Record<string, any>) {
      let filter = { ...this.filter, ...newFilters }
      this.$emit('update:filter', filter)
    },
    /**
     * Unfocus when datepicker closed
     */
    datePickerClosed(open: boolean) {
      if (!open) {
        this.startDateActive = false
        this.endDateActive = false
      }
    },
    /**
     * Disable dates
     * @param date
     * @return {boolean}
     */
    disabledDate(date: string): boolean {
      const dateDs = dayjs(date)
      let start = dayjs() // default today
      if (this.endDateActive && this.filter.startDate) {
        start = dayjs(this.filter.startDate)
      }
      return dateDs.isBefore(start, 'day')
    },
    disabledTime(date: string): boolean {
      const dateDs = dayjs(date)
      let start = dayjs() // default today
      if (this.endDateActive && this.filter.startDate) {
        start = dayjs(this.filter.startDate)
      }
      if (dateDs.isSame(start, 'day')) {
        return dateDs.isBefore(start, 'minute')
      } else {
        return false
      }
    },
    /**
     * Handle date input
     * @param date
     */
    handleDateChange(date: string): void {
      if (this.startDateActive) {
        console.log('check', this, date)
      } else {
        this.updateFilter('endDate', date)
      }
    },
    /**
     * Switch focus when start time is set
     */
    handleDateTimChange(date: string, unit: string): void {
      switch (unit) {
        case 'minute':
          if (this.startDateActive) {
            this.selectEndDate()
          }
      }
    },
    /**
     * Switch focus on tab key
     */
    handleTab(): void {
      if (this.searchActive) {
        this.selectStartDate()
      } else if (this.startDateActive) {
        this.selectEndDate()
      } else {
        // close date picker
      }
    },
  },
})
