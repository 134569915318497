
import vSelect from 'vue-select'
import BaseInput from '../BaseInput.vue'
import { createPopper } from '@popperjs/core'
import Vue, { PropType } from 'vue'
import { isEqual } from 'lodash'

type NativeOption = {
  key: string
  label: string
}

type RawOption = string | number | Record<string, string | unknown>

export default Vue.extend({
  name: 'BaseSelect',
  components: { BaseInput, vSelect },
  inheritAttrs: false,
  /**
   * Full Props: https://vue-select.org/api/props.html#value
   */
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean] as PropType<any>,
      default: null,
      required: false,
    },
    options: {
      type: Array as PropType<
        string[] | number[] | Record<string, string | number | unknown>[]
      >,
      default: () => [],
      required: true,
    },
    /**
     * Buddy label which is label for the input.
     */
    label: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    tooltip: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /**
     * Vue Select label prop.
     */
    optionLabel: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /**
     * Label key callback
     */
    getOptionLabel: {
      type: Function as PropType<((o: any) => string) | undefined>,
      required: false,
      default: undefined,
    },
    /**
     * Validation object;
     */
    validation: {
      type: Object,
      required: false,
      default: undefined,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    searchable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    clearable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    transparent: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    truncated: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    boldPlaceholder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rowStyle: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    embedded: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selectClass: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    autocomplete: {
      type: String as PropType<string>,
      default: 'off',
    },
    placement: {
      type: String as PropType<'bottom' | 'top'>,
      default: 'bottom',
      validator: (val) => ['bottom', 'top', 'bottom-start'].includes(val),
    },
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].includes(val),
    },
    createOption: {
      type: Function as PropType<(n: any) => any>,
      default: undefined,
      required: false,
    },
    inputId: {
      type: String as PropType<string | undefined>,
      default: undefined,
      required: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * Show loading indicator and block opening
     */
    showLoading: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /**
     * Flag to use popper instead of built-in position calculator#
     * Popper can handle sticky elements
     */
    usePopper: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     *
     */
    popperModifiers: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Flag for using native <select>
     */
    useNative: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popupWidth: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      lastValue: this.value,
    }
  },
  computed: {
    listeners(): Record<string, (e: any) => void> {
      return {
        ...this.$listeners,
        input: this.handleInput,
      }
    },
    /**
     * Map given options to standarized structure with
     * { label: optionLabel, key: optionKey }
     */
    nativeOptions(): { label: string; key: string }[] {
      return this.options.map(this.transformNativeOption)
    },
    nativeValue(): string {
      if (!this.value) {
        return ''
      }

      let reducedValue: RawOption = this.value
      if (Array.isArray(this.value) && this.value.length > 0) {
        reducedValue = this.value[0]
      }

      let unreducedValue = reducedValue
      // Find unreduced option, if we have a reduce function
      if (this.$attrs.reduce && typeof this.$attrs.reduce === 'function') {
        let index = this.options.findIndex((o: RawOption) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            JSON.stringify(this.$attrs.reduce(o)) ===
            JSON.stringify(reducedValue)
          )
        })

        unreducedValue = this.options[index]
      }

      let mappedValue = this.transformNativeOption(unreducedValue)

      return mappedValue.key
    },
    createOptionFunction(): (newOption: any) => any {
      if (typeof this.createOption === 'function') {
        return this.createOption
      } else if (this.optionLabel) {
        // If we defined an option label, we always want to return an object
        // Instead of returning an object only if we already have options loaded
        // See default behaviour: https://vue-select.org/api/props.html#createoption
        return (newOption) => ({
          [this.optionLabel!]: newOption,
        })
      }

      return (s) => s
    },
  },
  methods: {
    handleInput(event: string) {
      if (isEqual(event, this.value)) {
        return
      }
      if (this.validation) {
        this.validation.$touch()
      }
      this.$emit('input', event)
    },
    handleNativeSelect(value: string) {
      // find option by label and reduce this option
      let valueIndex = this.nativeOptions.findIndex((o) => o.key === value)

      if (valueIndex === -1) {
        this.$emit('input', value)
        return
      }

      let finalValue = this.options[valueIndex]

      if (this.$attrs.reduce) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        finalValue = this.$attrs.reduce(finalValue)
      }

      if (this.multiple) {
        this.$emit('input', [finalValue])
      } else {
        this.$emit('input', finalValue)
      }
    },
    /**
     *
     * @param option
     */
    transformNativeOption(
      option: string | number | Record<string, string | unknown>
    ): NativeOption {
      let label = ''
      let key = ''

      // get key
      if (typeof option === 'string' || typeof option === 'number') {
        label = option as string
        key = option as string
      } else if (typeof option === 'object') {
        // get key
        if (this.$attrs.getOptionKey) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          key = this.$attrs.getOptionKey(option)
        } else if (Object.prototype.hasOwnProperty.call(option, 'id')) {
          key = option!.id! as string
        } else {
          try {
            key = JSON.stringify(option)
          } catch (e) {
            console.warn(
              'BaseSelect.vue: could not create key for native option.'
            )
          }
        }
        // get label
        if (this.getOptionLabel) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label = this.getOptionLabel(option)
        } else if (
          this.optionLabel &&
          Object.prototype.hasOwnProperty.call(option, this.optionLabel)
        ) {
          label = option[this.optionLabel] as string
        } else {
          console.warn(
            'BaseSelect.vue: could not create label for native option.'
          )
        }
      } else {
        console.warn('BaseSelect.vue: Unknown how to get native options.')
      }

      return {
        label,
        key,
      }
    },
    handleBlur(event: any) {
      // touch validation if value changed
      if (this.validation && this.value !== this.lastValue) {
        this.validation.$touch()
        this.lastValue = this.value
      }
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('search:blur', event)
    },
    /**
     * Focus input of select
     */
    focus() {
      if (this.$refs.select) {
        let input = this.$el.querySelector('input')
        if (input) input.focus()
      }
    },
    withPopper(
      dropdownList: HTMLElement,
      component: any,
      { width }: { width: string }
    ) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      if (this.popupWidth) {
        width = this.popupWidth
      }
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'computeStyles',
            options: {
              adaptive: false,
            },
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...this.popperModifiers,
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
})
