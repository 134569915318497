
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'ViewSwitch',
  props: {
    view: {
      type: String,
      default: 'list',
    },
    buttonStyle: {
      type: String as PropType<'link' | 'button'>,
      default: 'link',
    },
  },
  methods: {
    toggleView(): void {
      this.$emit('update:view', this.view === 'list' ? 'map' : 'list')
    },
  },
})
