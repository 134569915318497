<template>
  <component
    :is="tabType"
    :tabs="tabs"
    :current-tab="currentTab"
    :data-selector="'category-tabs'"
    @onClick="updateCategory"
  >
  </component>
</template>

<script>
import TabBar from '../navigation/TabBar'
import ChipsNavigationBar from '@/shared/components/navigation/ChipsNavigationBar.vue'

export default {
  name: 'CategoryTabs',
  components: { TabBar },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    /**
     * Active category slug
     */
    activeCategory: {
      type: Object,
      required: false,
      default: undefined,
    },
    preselectFirst: {
      type: Boolean,
      default: true,
    },
    useChipsBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabType() {
      return this.useChipsBar ? ChipsNavigationBar : TabBar
    },
    /**
     * Map categories to tabs
     * prepend all tab
     */
    tabs() {
      let tabs = this.categories.map((c) => {
        return {
          value: c.slug,
          title: c.pluralName,
        }
      })
      if (this.useChipsBar) {
        return tabs
      }
      return [
        {
          value: '',
          title: this.$t('common.terms.all'),
        },
        ...tabs,
      ]
    },
    currentTab() {
      if (this.activeCategory) {
        return this.activeCategory.slug
      } else {
        return ''
      }
    },
  },
  watch: {
    categories() {
      if (this.activeCategory === undefined) {
        this.init()
      }
    },
  },
  mounted() {
    // check url params for pre-selected category
    if (this.categories.length > 0) {
      this.init()
    }
  },
  methods: {
    init() {
      if (this.$route.query.category) {
        this.updateCategory(this.$route.query.category, false, false)
      } else if (this.activeCategory) {
        this.updateQuery(this.activeCategory.slug)
      } else if (
        this.preselectFirst &&
        this.categories &&
        this.categories.length > 0
      ) {
        this.updateCategory(this.categories[0].slug, true, false)
      }
    },
    /**
     * Pass update event parent
     * @param categorySlug
     * @param setQuery
     * @param emitUpdate
     */
    updateCategory(categorySlug, setQuery = true, emitUpdate = true) {
      if (this.activeCategory?.slug === categorySlug) {
        if (emitUpdate) {
          // unselect category
          categorySlug = null
        } else {
          return
        }
      }
      let category =
        this.categories.find((c) => c.slug === categorySlug) ?? null
      this.$emit('update:activeCategory', category)
      if (emitUpdate) this.$emit('change', category)
      // add query params to url
      if (setQuery) {
        this.updateQuery(categorySlug)
      }
    },
    /**
     * Update url query params
     * @param categorySlug
     */
    updateQuery(categorySlug) {
      let query = {
        ...this.$route.query,
        category: categorySlug,
      }
      if (!categorySlug) {
        delete query.category
      }
      this.$router.push({
        path: this.$route.path,
        query,
      })
    },
  },
}
</script>

<style scoped></style>
