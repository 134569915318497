
import Vue, { PropType } from 'vue'
import { set } from 'lodash'
import BaseSelect from '../input/select/BaseSelect.vue'
import BaseInput from '../input/BaseInput.vue'
import LazyFa from '../utils/LazyFa.vue'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { ResourceFilter } from '@/shared/types/ResourceTypes'
import {
  Property,
  PropertyType,
} from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
/**
 * Schema for property filter
 * properties: { [propertyId: string]: { o: string (operator), v: string|number|array|boolean (value) } }
 */
type PropertyFilterExtended = {
  property: Property | null
  operator: string
  value: string | number | Array<any> | boolean | null
}

/**
 * Factory function for new filters
 */
function getNewFilter(): PropertyFilterExtended {
  return {
    property: null,
    operator: '',
    value: '',
  }
}

export default Vue.extend({
  name: 'FilterPanel',
  components: {
    BaseInput,
    BaseSelect,
    LazyFa,
  },
  props: {
    /**
     * { startDate, endDate }
     */
    filter: {
      type: Object as PropType<ResourceFilter>,
      required: true,
    },
    /**
     * Properties which may be filtered
     */
    properties: {
      type: Array as PropType<Property[]>,
      default: () => [],
      required: false,
    },
    filterContext: {
      type: Object as PropType<Resource|Organization|Community>,
      required: true,
    },
  },
  data() {
    return {
      showFilter: false,
      filterIcon: faFilter,
      operatorOptions: ['=', '>=', '<='],
      booleanOptions: ['true', 'false'],
      operatorTranslations: {
        '=': this.$i18n.t(
          'pages.organizations.index.resourceFilter.operators.isEqual'
        ),
        '>=': this.$i18n.t(
          'pages.organizations.index.resourceFilter.operators.isGreaterThan'
        ),
        '<=': this.$i18n.t(
          'pages.organizations.index.resourceFilter.operators.isLessThan'
        ),
        in: this.$i18n.t(
          'pages.organizations.index.resourceFilter.operators.isIn'
        ),
      },
      booleanDescriptions: {
        true: this.$i18n.t(
          'pages.organizations.index.resourceFilter.booleans.true'
        ),
        false: this.$i18n.t(
          'pages.organizations.index.resourceFilter.booleans.false'
        ),
      },
      isAddingFilter: false,
      newFilter: getNewFilter(),
      stringPropertyOptions: [] as string[],
      loadingPropertyValues: false,
    }
  },
  computed: {
    propertyMap(): Map<string, Property> {
      let map = new Map<string, Property>()
      if (this.properties) {
        this.properties.forEach((p: Property) => map.set(p.id, p))
      }
      return map
    },
    relevantPropertyIds(): string[] {
      if (!this.filter.properties) {
        return []
      }
      return Object.keys(this.filter.properties).filter((propertyId) =>
        this.propertyMap.has(propertyId)
      )
    },
    /**
     * Get contextualized filter for property options.
     */
    propertyOptionsFilter(): Record<string, any> {
      let filter: Record<string, any> = {
        ...this.filter,
      }
      if (this.filterContext instanceof Resource) {
        filter.resourceSlug = this.filterContext.slug
      }
      if (this.filterContext instanceof Organization) {
        filter.organizationSlug = this.filterContext.slug
      }
      if (this.filterContext instanceof Community) {
        filter.communitySlug = this.filterContext.slug
      }
      return filter
    },
  },
  methods: {
    /**
     * Update filter key and emit event
     *
     * @param {string} key
     * @param value
     */
    updateFilter(key: string, value: any): void {
      let filter = { ...this.filter }
      set(filter, key, value)
      this.$emit('update:filter', filter)
    },
    /**
     * Show create filter options
     */
    createNewFilter(): void {
      this.newFilter = getNewFilter()
      this.isAddingFilter = true
      if (this.$refs.propertySelect) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$nextTick(() => this.$refs.propertySelect.focus())
      }
    },
    /**
     * Handle property selection
     */
    async handlePropertySelect(property: Property) {
      if (!this.newFilter.property) {
        return
      }
      switch (this.newFilter.property.valueType) {
        case PropertyType.BOOLEAN:
          this.newFilter.operator = '='
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$nextTick(() => this.$refs.booleanSelect.focus())
          break
        case PropertyType.NUMBER:
          this.newFilter.operator = ''
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$nextTick(() => this.$refs.operatorSelect.focus())
          break
        case PropertyType.STRING: {
          this.newFilter.operator = 'in'
          this.stringPropertyOptions = []
          try {
            // request distinct options based on current filters
            let response = await property
              .api()
              .query({ filter: this.propertyOptionsFilter })
              .request('options')
            this.stringPropertyOptions = response.data
          } catch (e) {
            console.log()
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.$nextTick(() => this.$refs.stringOptionsSelect.focus())
        }
      }
    },
    handlePropertyBlur() {
      if (!this.newFilter.property) {
        this.isAddingFilter = false
      }
    },
    handleOperatorSelect() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.$nextTick(() => this.$refs.numberInput.focus())
    },
    /**
     * Add new filter
     */
    addFilter() {
      // validate filter
      if (!this.newFilter || !this.newFilter.property) {
        return
      }
      let isValidFilter = true
      switch (this.newFilter.property.valueType) {
        case PropertyType.BOOLEAN:
          if (
            !this.booleanOptions.includes(String(this.newFilter.value)) ||
            this.newFilter.operator !== '='
          ) {
            isValidFilter = false
          }
          break
        case PropertyType.NUMBER:
          if (
            !this.operatorOptions.includes(this.newFilter.operator) ||
            Number.isNaN(this.newFilter.value) ||
            !this.newFilter.value
          ) {
            isValidFilter = false
          }
          break
        case PropertyType.STRING:
          if (
            !Array.isArray(this.newFilter.value) ||
            this.newFilter.operator !== 'in'
          ) {
            isValidFilter = false
          }
      }
      if (isValidFilter) {
        // add filter
        let filter = { ...this.filter }
        if (!filter.properties) {
          filter.properties = {}
        }
        let newFilter = this.newFilter
        if (newFilter.property && newFilter.value) {
          filter.properties = {
            ...filter.properties,
            [newFilter.property.id]: {
              o: newFilter.operator,
              v: newFilter.value,
            },
          }
        }

        this.$emit('update:filter', filter)
      }
      this.isAddingFilter = false
    },
    /**
     * Remove filter
     * @param propertyId
     */
    removeFilter(propertyId: string) {
      let filter = { ...this.filter }
      if (filter.properties) {
        // create new object for properties for reactivity
        let properties = { ...filter.properties }
        delete properties[propertyId]
        filter.properties = properties
      }
      this.$emit('update:filter', filter)
    },
  },
})
