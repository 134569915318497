const abbreviations = {
  'Pacific/Niue': 'NUT',
  'Pacific/Midway': 'SST',
  'Pacific/Pago_Pago': 'SST',
  'Pacific/Rarotonga': 'CKT',
  'Pacific/Honolulu': 'HAST',
  'Pacific/Tahiti': 'TAHT',
  'Pacific/Marquesas': 'MART',
  'Pacific/Gambier': 'GAMT',
  'America/Adak': 'HAST',
  'America/Anchorage': 'AKST',
  'Pacific/Pitcairn': 'PST',
  'America/Hermosillo': 'GMT-7',
  'America/Phoenix': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Tijuana': 'PST',
  'America/Vancouver': 'PST',
  'America/Whitehorse': 'YT',
  'America/Belize': 'CST',
  'America/Guatemala': 'CST',
  'America/Managua': 'CST',
  'America/Mexico_City': 'CST',
  'America/Costa_Rica': 'CST',
  'America/El_Salvador': 'CST',
  'America/Regina': 'CST',
  'America/Tegucigalpa': 'CST',
  'Pacific/Galapagos': 'GALT',
  'America/Edmonton': 'MST',
  'America/Ciudad_Juarez': 'MST',
  'America/Denver': 'MST',
  'America/Rio_Branco': 'ACT',
  'America/Chicago': 'CST',
  'America/Matamoros': 'CST',
  'America/Winnipeg': 'CST',
  'America/Bogota': 'COT',
  'Pacific/Easter': 'EAST',
  'America/Atikokan': 'EST',
  'America/Cancun': 'EST',
  'America/Cayman': 'EST',
  'America/Jamaica': 'EST',
  'America/Panama': 'EST',
  'America/Guayaquil': 'ECT',
  'America/Lima': 'PET',
  'America/Manaus': 'AMT',
  'America/St_Kitts': 'AST',
  'America/Blanc-Sablon': 'AST',
  'America/Montserrat': 'AST',
  'America/Barbados': 'AST',
  'America/St_Lucia': 'AST',
  'America/Port_of_Spain': 'AST',
  'America/Martinique': 'AST',
  'America/St_Barthelemy': 'AST',
  'America/St_Vincent': 'AST',
  'America/Kralendijk': 'AST',
  'America/Guadeloupe': 'AST',
  'America/Marigot': 'AST',
  'America/Aruba': 'AST',
  'America/Lower_Princes': 'AST',
  'America/Tortola': 'AST',
  'America/Dominica': 'AST',
  'America/St_Thomas': 'AST',
  'America/Grenada': 'AST',
  'America/Antigua': 'AST',
  'America/Puerto_Rico': 'AST',
  'America/Santo_Domingo': 'AST',
  'America/Anguilla': 'AST',
  'America/Curacao': 'AST',
  'America/La_Paz': 'BOT',
  'America/Havana': 'CST',
  'America/Grand_Turk': 'EST',
  'America/Nassau': 'EST',
  'America/New_York': 'EST',
  'America/Port-au-Prince': 'EST',
  'America/Toronto': 'EST',
  'America/Guyana': 'GYT',
  'America/Caracas': 'VET',
  'America/Argentina/Buenos_Aires': 'ART',
  'America/Halifax': 'AST',
  'Atlantic/Bermuda': 'AST',
  'America/Thule': 'AST',
  'America/Sao_Paulo': 'BRT',
  'Antarctica/Palmer': 'CLT',
  'America/Punta_Arenas': 'CLT',
  'America/Santiago': 'CLT',
  'Atlantic/Stanley': 'FKST',
  'America/Cayenne': 'GFT',
  'America/Asuncion': 'PYT',
  'America/Paramaribo': 'SRT',
  'America/Montevideo': 'UYT',
  'America/St_Johns': 'NST',
  'America/Noronha': 'FNT',
  'Atlantic/South_Georgia': 'GST',
  'America/Miquelon': 'PM',
  'America/Nuuk': 'WGT',
  'Atlantic/Azores': 'AZOT',
  'Atlantic/Cape_Verde': 'CVT',
  'America/Scoresbysund': 'EGT',
  'Africa/Abidjan': 'GMT',
  'Africa/Accra': 'GMT',
  'Africa/Bamako': 'GMT',
  'Africa/Bissau': 'GMT',
  'Africa/Conakry': 'GMT',
  'Africa/Dakar': 'GMT',
  'America/Danmarkshavn': 'GMT',
  'Europe/Isle_of_Man': 'GMT',
  'Europe/Dublin': 'GMT',
  'Africa/Freetown': 'GMT',
  'Atlantic/St_Helena': 'GMT',
  'Africa/Lome': 'GMT',
  'Europe/London': 'GMT',
  'Africa/Monrovia': 'GMT',
  'Africa/Nouakchott': 'GMT',
  'Africa/Ouagadougou': 'GMT',
  'Atlantic/Reykjavik': 'GMT',
  'Europe/Jersey': 'GMT',
  'Europe/Guernsey': 'GMT',
  'Africa/Sao_Tome': 'GMT',
  'Africa/Banjul': 'GMT',
  'Antarctica/Troll': 'GMT',
  'Atlantic/Canary': 'WET',
  'Europe/Lisbon': 'WET',
  'Atlantic/Faroe': 'WET',
  'Africa/Algiers': 'CET',
  'Europe/Amsterdam': 'CET',
  'Europe/Andorra': 'CET',
  'Europe/Belgrade': 'CET',
  'Europe/Berlin': 'CET',
  'Europe/Bratislava': 'CET',
  'Europe/Brussels': 'CET',
  'Europe/Budapest': 'CET',
  'Europe/Copenhagen': 'CET',
  'Europe/Gibraltar': 'CET',
  'Europe/Ljubljana': 'CET',
  'Arctic/Longyearbyen': 'CET',
  'Europe/Luxembourg': 'CET',
  'Europe/Madrid': 'CET',
  'Europe/Monaco': 'CET',
  'Europe/Oslo': 'CET',
  'Europe/Paris': 'CET',
  'Europe/Podgorica': 'CET',
  'Europe/Prague': 'CET',
  'Europe/Rome': 'CET',
  'Europe/San_Marino': 'CET',
  'Europe/Malta': 'CET',
  'Europe/Sarajevo': 'CET',
  'Europe/Skopje': 'CET',
  'Europe/Stockholm': 'CET',
  'Europe/Tirane': 'CET',
  'Africa/Tunis': 'CET',
  'Europe/Vaduz': 'CET',
  'Europe/Vatican': 'CET',
  'Europe/Vienna': 'CET',
  'Europe/Warsaw': 'CET',
  'Europe/Zagreb': 'CET',
  'Europe/Zurich': 'CET',
  'Africa/Bangui': 'WAT',
  'Africa/Malabo': 'WAT',
  'Africa/Brazzaville': 'WAT',
  'Africa/Porto-Novo': 'WAT',
  'Africa/Douala': 'WAT',
  'Africa/Kinshasa': 'WAT',
  'Africa/Lagos': 'WAT',
  'Africa/Libreville': 'WAT',
  'Africa/Luanda': 'WAT',
  'Africa/Ndjamena': 'WAT',
  'Africa/Niamey': 'WAT',
  'Africa/Casablanca': 'WET',
  'Africa/El_Aaiun': 'WET',
  'Africa/Bujumbura': 'CAT',
  'Africa/Gaborone': 'CAT',
  'Africa/Harare': 'CAT',
  'Africa/Juba': 'CAT',
  'Africa/Khartoum': 'CAT',
  'Africa/Kigali': 'CAT',
  'Africa/Blantyre': 'CAT',
  'Africa/Lubumbashi': 'CAT',
  'Africa/Lusaka': 'CAT',
  'Africa/Maputo': 'CAT',
  'Africa/Windhoek': 'CAT',
  'Europe/Athens': 'EET',
  'Asia/Beirut': 'EET',
  'Europe/Bucharest': 'EET',
  'Africa/Cairo': 'EET',
  'Europe/Chisinau': 'EET',
  'Asia/Hebron': 'EET',
  'Europe/Helsinki': 'EET',
  'Europe/Kaliningrad': 'EET',
  'Europe/Kyiv': 'EET',
  'Europe/Mariehamn': 'EET',
  'Asia/Nicosia': 'EET',
  'Europe/Riga': 'EET',
  'Europe/Sofia': 'EET',
  'Europe/Tallinn': 'EET',
  'Africa/Tripoli': 'EET',
  'Europe/Vilnius': 'EET',
  'Asia/Jerusalem': 'IST',
  'Africa/Johannesburg': 'SAST',
  'Africa/Mbabane': 'SAST',
  'Africa/Maseru': 'SAST',
  'Asia/Kuwait': 'AST',
  'Asia/Bahrain': 'AST',
  'Asia/Baghdad': 'AST',
  'Asia/Qatar': 'AST',
  'Asia/Riyadh': 'AST',
  'Asia/Aden': 'AST',
  'Asia/Amman': 'GMT+3',
  'Asia/Damascus': 'GMT+3',
  'Africa/Addis_Ababa': 'EAT',
  'Indian/Antananarivo': 'EAT',
  'Africa/Asmara': 'EAT',
  'Africa/Dar_es_Salaam': 'EAT',
  'Africa/Djibouti': 'EAT',
  'Africa/Kampala': 'EAT',
  'Indian/Mayotte': 'EAT',
  'Africa/Mogadishu': 'EAT',
  'Indian/Comoro': 'EAT',
  'Africa/Nairobi': 'EAT',
  'Europe/Minsk': 'MSK',
  'Europe/Moscow': 'MSK',
  'Europe/Simferopol': 'MSK',
  'Antarctica/Syowa': 'SYOT',
  'Europe/Istanbul': 'TRT',
  'Asia/Tehran': 'IRST',
  'Asia/Yerevan': 'AMT',
  'Asia/Baku': 'AZT',
  'Asia/Tbilisi': 'GET',
  'Asia/Dubai': 'GST',
  'Asia/Muscat': 'GST',
  'Indian/Mauritius': 'MUT',
  'Indian/Reunion': 'RET',
  'Europe/Samara': 'SAMT',
  'Indian/Mahe': 'SCT',
  'Asia/Kabul': 'AFT',
  'Indian/Kerguelen': 'FSAT',
  'Indian/Maldives': 'MVT',
  'Antarctica/Mawson': 'MAWT',
  'Asia/Karachi': 'PKT',
  'Asia/Dushanbe': 'TJT',
  'Asia/Ashgabat': 'TMT',
  'Asia/Tashkent': 'UZT',
  'Asia/Aqtobe': 'AQTT',
  'Asia/Yekaterinburg': 'YEKT',
  'Asia/Colombo': 'IST',
  'Asia/Kolkata': 'IST',
  'Asia/Kathmandu': 'NPT',
  'Asia/Dhaka': 'BST',
  'Asia/Thimphu': 'BTT',
  'Asia/Urumqi': 'CST',
  'Asia/Almaty': 'ALMT',
  'Indian/Chagos': 'IOT',
  'Asia/Bishkek': 'KGT',
  'Asia/Omsk': 'OMST',
  'Antarctica/Vostok': 'VOST',
  'Indian/Cocos': 'CCT',
  'Asia/Yangon': 'MMT',
  'Indian/Christmas': 'CXT',
  'Antarctica/Davis': 'DAVT',
  'Asia/Hovd': 'HOVT',
  'Asia/Bangkok': 'ICT',
  'Asia/Ho_Chi_Minh': 'ICT',
  'Asia/Phnom_Penh': 'ICT',
  'Asia/Vientiane': 'ICT',
  'Asia/Novosibirsk': 'NOVT',
  'Asia/Jakarta': 'WIB',
  'Australia/Perth': 'AWST',
  'Asia/Brunei': 'BNT',
  'Asia/Makassar': 'WITA',
  'Asia/Macau': 'CST',
  'Asia/Shanghai': 'CST',
  'Asia/Hong_Kong': 'HKT',
  'Asia/Irkutsk': 'IRKT',
  'Asia/Kuala_Lumpur': 'MYT',
  'Asia/Manila': 'PHT',
  'Asia/Singapore': 'SGT',
  'Asia/Taipei': 'TWT',
  'Asia/Ulaanbaatar': 'ULAT',
  'Australia/Eucla': 'ACWST',
  'Asia/Dili': 'TLT',
  'Asia/Jayapura': 'WIT',
  'Asia/Tokyo': 'JST',
  'Asia/Pyongyang': 'KST',
  'Asia/Seoul': 'KST',
  'Pacific/Palau': 'PWT',
  'Asia/Chita': 'YAKT',
  'Australia/Darwin': 'ACST',
  'Australia/Brisbane': 'AEST',
  'Pacific/Guam': 'ChST',
  'Pacific/Saipan': 'ChST',
  'Pacific/Chuuk': 'CHUT',
  'Antarctica/DumontDUrville': 'DDUT',
  'Pacific/Port_Moresby': 'PGT',
  'Asia/Vladivostok': 'VLAT',
  'Australia/Adelaide': 'ACST',
  'Australia/Sydney': 'AEST',
  'Pacific/Bougainville': 'BST',
  'Antarctica/Casey': 'CAST',
  'Pacific/Kosrae': 'KOST',
  'Australia/Lord_Howe': 'LHST',
  'Pacific/Noumea': 'NCT',
  'Asia/Sakhalin': 'SAKT',
  'Pacific/Guadalcanal': 'SBT',
  'Pacific/Efate': 'VUT',
  'Pacific/Fiji': 'FJT',
  'Pacific/Tarawa': 'GILT',
  'Pacific/Majuro': 'MHT',
  'Pacific/Nauru': 'NRT',
  'Pacific/Norfolk': 'NFT',
  'Asia/Kamchatka': 'PETT',
  'Pacific/Funafuti': 'TVT',
  'Pacific/Wake': 'WAKT',
  'Pacific/Wallis': 'WFT',
  'Pacific/Apia': 'WST',
  'Pacific/Auckland': 'NZST',
  'Antarctica/McMurdo': 'NZST',
  'Pacific/Kanton': 'PHOT',
  'Pacific/Fakaofo': 'TKT',
  'Pacific/Tongatapu': 'TOT',
  'Pacific/Chatham': 'CHAST',
  'Pacific/Kiritimati': 'LINT',
}

export function getAbbreviation(
  timezone: string,
  offset?: number
): string | undefined {
  // DEV: Update mapping if needed
  // const timezones = getTimeZones()
  // const map = {}
  // timezones.forEach((tz) => {
  //   map[tz.name] = tz.abbreviation
  // })
  // console.log(map)
  const abbreviation = abbreviations[timezone]
  if (abbreviation && offset !== undefined) {
    const sign = offset >= 0 ? '+' : '-'
    const absOffset = Math.abs(offset)
    const hours = Math.floor(absOffset / 60)
    const mins = absOffset % 60
    const formattedHours = hours.toString().padStart(2, '0')
    let formattedOffset = `${sign}${formattedHours}`

    // Append minutes to the offset if they are non-zero
    if (mins > 0) {
      const formattedMinutes = mins.toString().padStart(2, '0')
      formattedOffset += `:${formattedMinutes}`
    }

    return `${abbreviation} (UTC${formattedOffset})`
  }
  return abbreviation
}

export function formattedUtcOffset(minutes: number): string {
  // Determine the sign of the offset
  const sign = minutes >= 0 ? '+' : '-'

  // Convert the minutes into absolute value
  const absMinutes = Math.abs(minutes)

  // Calculate hours and minutes
  const hours = Math.floor(absMinutes / 60)
  const mins = absMinutes % 60

  // Format the hours and minutes as two digits
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = mins.toString().padStart(2, '0')

  // Return the formatted string
  return `${sign}${formattedHours}:${formattedMinutes}`
}
