<template>
  <div>
    <div class="eb-text-small">
      <!-- Weekday -->
      <span v-if="dateIsValid && date">
        {{ dayjs.format('ddd') }}
      </span>
      <span v-else> --- </span>

      <!-- Time-->
      <slot name="time">
        <template v-if="displayTime" :date="date">
          <fa :icon="['far', 'clock']" class="fa-fw" />
          <span v-if="timeIsValid && date">
            {{ dayjs.format('LT') }}
          </span>
          <span v-else> --:-- </span>
          <span
            v-if="showTimezone && timezoneAbbr"
            v-tooltip="timezoneTooltip"
            class="eb-text-tertiary ms-1"
          >
            {{ timezoneAbbr }}
          </span>
        </template>
      </slot>
    </div>
    <h5 class="m-0 fw-normal">
      <span v-if="dateIsValid && date">
        {{ dayjs.format('L') }}
      </span>
      <span v-else>
        {{ datePlaceholder }}
      </span>
    </h5>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  formattedUtcOffset,
  getAbbreviation,
} from '@/shared/utils/datetime/timezoneHelpers'

export default {
  name: 'Date',
  props: {
    date: {
      type: [String, Date],
      required: false,
      default: undefined,
    },
    displayTime: {
      type: Boolean,
      default: true,
    },
    timeIsValid: {
      type: Boolean,
      default: true,
    },
    dateIsValid: {
      type: Boolean,
      default: true,
    },
    datePlaceholder: {
      type: String,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: null,
    },
    showTimezone: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dayjs() {
      if (this.timezone) {
        return dayjs(this.date).tz(this.timezone)
      }
      return dayjs(this.date)
    },
    timezoneAbbr() {
      const offset = this.dayjs.utcOffset()
      if (this.timezone) {
        return getAbbreviation(this.timezone) ?? ''
      }
      return 'UTC' + formattedUtcOffset(offset)
    },
    timezoneTooltip() {
      const offset = this.dayjs.utcOffset()
      const offsetString = 'UTC' + formattedUtcOffset(offset)
      if (this.timezone) {
        return `${this.timezone} (${offsetString})`
      }
      return offsetString
    },
  },
}
</script>

<style scoped></style>
