var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.useNative && !_vm.multiple)?_c('BaseInput',{attrs:{"label":_vm.label,"validation":_vm.validation,"tooltip":_vm.tooltip,"row-style":_vm.rowStyle,"no-border":_vm.noBorder,"autocomplete":_vm.autocomplete,"input-id":_vm.inputId},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('select',{style:({
          color:
            _vm.nativeValue === '' ? 'var(--text-tertiary-color)' : undefined,
        }),attrs:{"disabled":_vm.disabled},domProps:{"value":_vm.nativeValue},on:{"input":function($event){return _vm.handleNativeSelect($event.target.value)},"click":function($event){return _vm.$emit('native-select-click')}}},[(_vm.placeholder ? _vm.placeholder : _vm.label)?_c('option',{staticClass:"eb-text-tertiary",attrs:{"value":"","disabled":"","hidden":""}},[_vm._v("\n          "+_vm._s(_vm.placeholder ? _vm.placeholder : _vm.label)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.nativeOptions),function(option){return _c('option',{key:option.key,domProps:{"value":option.key}},[_vm._v("\n          "+_vm._s(option.label)+"\n        ")])})],2)]},proxy:true}],null,false,90753299)}):_c('BaseInput',{attrs:{"label":_vm.label,"validation":_vm.validation,"tooltip":_vm.tooltip,"row-style":_vm.rowStyle,"no-border":_vm.noBorder,"autocomplete":_vm.autocomplete,"input-id":_vm.inputId,"embedded":_vm.embedded},scopedSlots:_vm._u([{key:"input-prefix",fn:function(){return [_vm._t("input-prefix")]},proxy:true},{key:"input",fn:function({ uid }){return [_c('v-select',_vm._g(_vm._b({ref:"select",staticClass:"eb-v-select",class:{
          'eb-v-select-multiple': _vm.multiple,
          ..._vm.selectClass,
          'eb-v-select-top': _vm.placement === 'top',
          'eb-v-select-right': _vm.align === 'right',
          'hide-input': !_vm.searchable,
          'transparent-bg collapse': _vm.transparent || _vm.rowStyle,
          'eb-v-select-truncated': _vm.truncated,
          'bold-placeholder': _vm.boldPlaceholder,
        },attrs:{"transition":_vm.usePopper ? undefined : 'eb-dropdown',"value":_vm.value,"options":_vm.options,"label":_vm.optionLabel,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"input-id":uid,"multiple":_vm.multiple,"clearable":_vm.clearable,"searchable":_vm.searchable,"select-on-tab":true,"autocomplete":_vm.autocomplete,"create-option":_vm.createOptionFunction,"get-option-label":_vm.getOptionLabel,"loading":_vm.showLoading && _vm.loading,"calculate-position":_vm.usePopper ? _vm.withPopper : undefined,"disabled":_vm.disabled},on:{"search:blur":_vm.handleBlur},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),(_vm.$slots.hasOwnProperty('selected-option'))?{key:"selected-option",fn:function(option){return [_c('label',{attrs:{"for":uid}},[_vm._t("selected-option",null,null,option)],2)]}}:null,{key:"spinner",fn:function({ loading: l }){return [_vm._t("spinner",function(){return [_c('fa',{directives:[{name:"show",rawName:"v-show",value:(l),expression:"l"}],staticClass:"eb-fast-spin fa-fw",attrs:{"icon":['fad', 'spinner-third']}})]},{"loading":l})]}},{key:"no-options",fn:function(){return [_vm._t("no-options",function(){return [(!_vm.loading)?_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t('common.errors.noOptions')))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.status.loading'))+"\n              "),_c('fa',{staticClass:"eb-fast-spin",attrs:{"icon":['far', 'spinner-third']}})],1)]})]},proxy:true},{key:"open-indicator",fn:function({ attributes }){return [_vm._t("open-indicator",function(){return [_c('span',_vm._b({staticClass:"eb-text-tertiary"},'span',attributes,false),[_c('fa',{attrs:{"icon":['far', 'angle-down']}})],1)]},{"attributes":attributes})]}}],null,true)},'v-select',_vm.$attrs,false),_vm.listeners))]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }