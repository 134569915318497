
import Vue, { PropOptions } from 'vue'
import HorizontalScroll from '@/shared/components/utils/HorizontalScroll.vue'
import {
  TabBarOption,
  TabBarOptionValue,
} from '@/shared/components/navigation/TabBar.vue'
export default Vue.extend({
  name: 'ChipsNavigationBar',
  components: {
    HorizontalScroll,
  },
  props: {
    /**
     * Tabs array with { title: string, value: string, route: object }
     */
    tabs: {
      type: Array,
      default: () => [],
    } as PropOptions<TabBarOption[]>,
    /**
     * Value of active tab
     */
    currentTab: {
      type: [String, Number],
      required: false,
      default: null,
    } as PropOptions<TabBarOptionValue>,
    /**
     * Use nuxt-link instead of a link
     */
    useNuxtLink: {
      type: Boolean,
      default: false,
    },
    useButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    linkType(): string {
      return this.useNuxtLink ? 'nuxt-link' : this.useButton ? 'button' : 'a'
    },
    visibleTabs(): TabBarOption[] {
      return this.tabs.filter((tab) => {
        return tab.hidden === undefined || tab.hidden === false
      })
    },
  },
  methods: {
    /**
     * Get link bindings
     * @param tab
     * @returns {{to: *}|{href: string}}
     */
    getLinkBindings(tab: TabBarOption) {
      if (this.useNuxtLink) {
        return {
          to: tab.route,
        }
      } else {
        return {
          href: '#',
        }
      }
    },
    /**
     *
     * @param tab {{title: string}, {value: string}}
     * @param event {Event}
     */
    handleClick(tab: TabBarOption, event: MouseEvent) {
      if (this.useNuxtLink) {
        // automated routing by nuxt
      } else {
        this.$emit('onClick', tab.value)
        event.preventDefault()
      }
      this.$emit('update:currentTab', tab.value)
    },
  },
})
